import Image from 'next/image';
import React from 'react';

export const HomeownerBanner = () => {
  return (
    <Image
      src="/images/homeowner-banner.png"
      height="45"
      width="100%"
      layout="responsive"
      alt="Home Owner Image"
    />
  );
};
