import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import React from 'react';

// import { ContactUs } from '@modules/client/home/ContactUs';
import { Family } from '@modules/client/home/Family';
import { getClientLayout } from '@modules/client/home/header/ClientLayout';
import { HomeownerBanner } from '@modules/client/home/HomeownerBanner';
import { HowItWorks } from '@modules/client/home/HowItWorks';
import { MainImage } from '@modules/client/home/MainImage';
import { OurServices } from '@modules/client/home/OurServices';

const WhatPeopleSay = dynamic(
  () => import('@modules/client/home/WhatPeopleSay'),
  {
    ssr: false,
  },
);

const IndexPage = () => {
  return (
    <>
      <style jsx>{`
        imageContainer {
          position: relative;
        }
      `}</style>
      <NextSeo
        title="Qeeper"
        description="On-demand property maintenance across London, Edinburgh and Brighton"
        canonical="https://www.qeeper.co.uk"
        openGraph={{
          url: 'https://www.qeeper.co.uk',
          title: 'Qeeper',
          description:
            'On-demand property maintenance across London, Edinburgh and Brighton',
        }}
      />
      <MainImage />
      <Family />
      <HowItWorks />
      <OurServices />
      <HomeownerBanner />
      <WhatPeopleSay />
      {/* <ContactUs /> */}
    </>
  );
};

IndexPage.getLayout = getClientLayout;

export default IndexPage;
