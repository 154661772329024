import { MdWarning } from 'react-icons/md';

import { IRules } from '@common/constants';
import { Box } from '@mui/system';

export const DisplayErrors = ({ message }: { message: string | string[] }) => {
  if (message) {
    return (
      <Box sx={{ mb: 2, ml: 1, fontSize: 14 }}>
        {errorMessageUtil(message)}
        {/* {rules && rulesValidation(true, watch(name), rules)} */}
      </Box>
    );
  } else {
    return <span />;
  }
};

export const errorMessageUtil = (errorMessage: any) => {
  if (errorMessage && Array.isArray(errorMessage) && errorMessage.length > 0) {
    return errorMessage.map(function (item: any, key: any) {
      return <span key={key}>{errorLine(true, item, true)}</span>;
    });
  } else if (errorMessage && errorMessage.length > 0) {
    return (
      <span key={errorMessage}>{errorLine(true, errorMessage, true)}</span>
    );
  } else {
    return null;
  }
};

export const errorLine = (
  isTouched: any,
  errorText: string,
  isError: boolean
) => {
  let color = 'black';
  if (isTouched) {
    color = 'green';
    if (isError) {
      color = 'red';
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '5px 0',
        color
      }}
      key={errorText}
    >
      <MdWarning style={{ marginRight: 5 }} size={24} />
      <span>{errorText}</span>
    </div>
  );
};

export const rulesValidation = (
  isTouched: any,
  value: string,
  rules: IRules[]
) => {
  return (
    <div style={{ marginBottom: 5 }}>
      {rules.map((rule) =>
        errorLine(isTouched, rule.message, !value.match(rule.rule))
      )}
    </div>
  );
};
