import Image from 'next/image';
import React from 'react';

import { Container, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const RowItem = ({ title, subtitle, imagesrc, price }: any) => (
  <Grid item xs={8} sm={6} md={4} sx={{ width: '100%' }}>
    <Item
      sx={{ display: 'flex', flexDirection: 'column', flexBasis: '100%' }}
      elevation={4}>
      <Typography variant="h6" gutterBottom component="div" color="inherit">
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{ mb: 2 }}
        color="inherit">
        {subtitle}
      </Typography>
      <Container>
        <Image
          src={imagesrc}
          height="80"
          width="80"
          layout="fixed"
          alt="Our services image"
        />
      </Container>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ mt: 2 }}
        color="inherit">
        {price}
      </Typography>
    </Item>
  </Grid>
);
export const OurServices = () => {
  return (
    <Container
      sx={{
        marginTop: 5,
        textAlign: 'center',
        marginBottom: 10,
      }}>
      <Typography
        variant="h4"
        gutterBottom
        component="div"
        color="primary"
        sx={{ fontWeight: 500, marginBottom: 5 }}>
        Our Services
      </Typography>

      <Grid container spacing={4}>
        <RowItem
          title="Heating & Gas Engineer"
          subtitle="Boiler repairs, central heating systems, repairing gas appliances,
              radiators and more."
          price="From £105 + VAT"
          imagesrc="/svg/radiator.svg"
        />
        <RowItem
          title="Plumber"
          subtitle="Fixing leaks, repairing taps, toilets, baths, showers, sinks and more."
          price="From £95 + VAT"
          imagesrc="/svg/faucet.svg"
        />
        <RowItem
          title="Electrician"
          subtitle="Fault finding, safety tests, light fittings, sockets, fuse boxes and more."
          price="From £95 + VAT"
          imagesrc="/svg/flash.svg"
        />
        <RowItem
          title="Portable appliance testing (PAT)"
          subtitle="Portable appliance testing (PAT)"
          price="From £85 + VAT"
          imagesrc="/svg/PAT.svg"
        />

        <RowItem
          title="Handyman"
          subtitle="Odd jobs, repair, assembling, fixing and more."
          price="From £70 + VAT"
          imagesrc="/svg/hammer.svg"
        />
        <RowItem
          title="GAS Safety Certificates"
          subtitle="Gas safety certificates"
          price="From £85 + VAT"
          imagesrc="/svg/certificate.png"
        />

        <RowItem
          title="Appliance Engineer"
          subtitle="Household appliance repairs, washing machines, dishwashers, ovens and more."
          price="From £80 + VAT"
          imagesrc="/svg/washing-machine.svg"
        />

        <RowItem
          title="electrical installation condition report (EICR) - Studio/1 bed"
          subtitle="electrical installation condition report (EICR) - Studio/1 bed"
          price="From £149 + VAT"
          imagesrc="/svg/certificate.png"
        />
        <RowItem
          title="electrical installation condition report (EICR) - 2 bed"
          subtitle="electrical installation condition report (EICR) - 2 bed"
          price="From £159 + VAT"
          imagesrc="/svg/certificate.png"
        />
        <RowItem
          title="electrical installation condition report (EICR) - 3 bed"
          subtitle="electrical installation condition report (EICR) - 3 bed"
          price="From £169 + VAT"
          imagesrc="/svg/certificate.png"
        />
        <RowItem
          title="electrical installation condition report (EICR) - 4 bed"
          subtitle="electrical installation condition report (EICR) - 4 bed"
          price="From £179 + VAT"
          imagesrc="/svg/certificate.png"
        />
      </Grid>
    </Container>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  height: '100%',
  width: '100%',
}));
