import Image from 'next/image';
import React from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from '@components/Link';

export const HowItWorks = () => {
  const RowItem = ({ imageSrc, text }: any) => (
    <Grid item xs={8} sm={8} md={4}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 5,
        }}>
        <Image
          src={imageSrc}
          height="140"
          width="140"
          layout="fixed"
          alt="How it works Image"
        />
        <div style={{ marginTop: 20 }}>{text}</div>
      </Box>
    </Grid>
  );
  return (
    <Box
      sx={{
        width: '100%',
        paddingY: 10,
        textAlign: 'center',
        backgroundColor: '#f4f4f4',
      }}>
      <Typography
        variant="h4"
        gutterBottom
        component="div"
        color="primary"
        sx={{ fontWeight: 500, mb: 8 }}>
        How it works?
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'center', paddingX: 4 }}>
        <RowItem
          imageSrc="/images/sign-up.png"
          text="(1) Select the type of engineer you need."
        />
        <RowItem
          imageSrc="/images/verify.png"
          text="(2) Choose a convenient time for their visit."
        />
        <RowItem
          imageSrc="/images/qeeper-maintenance.png"
          text="(3) Your engineer will arrive at the time you requested."
        />
      </Grid>

      <Button
        size="medium"
        aria-label="Email Us"
        color="primary"
        variant="contained"
        component={Link}
        sx={{textTransform: "none", alignSelf: 'center', mt: 5}}
        href="mailto: request@qeeper.co.uk">
        Email Us
      </Button>
    </Box>
  );
};
