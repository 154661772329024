import Image from 'next/image';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Link } from '@components/Link';
import {
  AutoCompleteCheckInput,
} from '@modules/shared/input/AutoCompleteCheckInput';
import { LoadingButton } from '@mui/lab';
import { Container, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

export const MainImage = () => {
  const methods = useForm({});

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: { xs: 500, sm: 600, md: 700 },
        }}>
        <Image
          src="/images/header_image_qeeper.jpg"
          layout="fill"
          alt="Qeeper Header Image"
        />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
        }}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: -10,
          }}>
          <Typography
            variant="h3"
            gutterBottom
            component="div"
            sx={{
              color: 'white',
              fontWeight: 500,
              display: { xs: 'none', sm: 'block' },
            }}>
            Something broken...?
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            component="div"
            sx={{ color: 'white', fontWeight: 500 }}>
            Book reliable home repairs instantly.
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              color: 'white',
              marginTop: 2,
              marginBottom: 4,
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}>
            On-demand property maintenance across London, Edinburgh & Brighton.
          </Typography>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: '#00396b',
              height: 80,
              display: 'flex',
              flexDirection: 'row',
              width: 800,
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}>
            <FormProvider {...methods}>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                sx={{ color: 'white', fontWeight: 500 }}>
                Select your service:
              </Typography>
              <AutoCompleteCheckInput
                name="service"
                propertyName="name"
                options={list}
                multiple={false}
                freeSolo={false}
                style={{ backgroundColor: 'white', minWidth: 300 }}
              />
              <LoadingButton
                size="medium"
                aria-label="book online"
                color="primary"
                variant="contained"
                sx={{ alignSelf: 'center' }}
                component={Link}
                href="mailto: request@qeeper.co.uk">
                Email Us
              </LoadingButton>
            </FormProvider>
          </Paper>
          <Link
            noWrap
            href="https://uk.trustpilot.com/review/qeeper.co.uk"
            target="_blank">
            <Image
              src="/images/trustreview.png"
              height="100"
              width="220"
              alt="Qeeper TrustPilot reviews"
            />
          </Link>
        </Container>
      </Box>
    </Box>
  );
};

const list = [
  { id: 1, name: 'Gas & Heating (From £105 + VAT)' },
  { id: 2, name: 'Plumber (From £95 + VAT)' },
  { id: 3, name: 'Electrician (From £95 + VAT)' },
  { id: 4, name: 'Appliance engineer (From £80 + VAT)' },
  { id: 5, name: 'Handyperson (From £70 + VAT)' },
  { id: 6, name: 'Gas safety certificate (GSC) (From £85 + VAT)' },
  { id: 7, name: 'Energy perfromance certificate (EPC) (From £85 + VAT)' },
  { id: 8, name: 'Portable appliance testing (PAT) (From £85 + VAT)' },
  { id: 9, name: 'electrical installation condition report (EICR) - Studio/1 bed (From £149 + VAT)' },
  { id: 10, name: 'electrical installation condition report (EICR) - 2 bed (From £159 + VAT)' },
  { id: 11, name: 'electrical installation condition report (EICR) - 3 bed (From £169 + VAT)' },
  { id: 12, name: 'electrical installation condition report (EICR) - 4 bed (From £179 + VAT)' },
  { id: 13, name: 'Not sure' },
];
