import Image from 'next/image';
import React from 'react';

import { Container, Grid } from '@mui/material';

export const Family = () => {
  return (
    <Container
      sx={{
        marginTop: 5,
        marginBottom: 10,
      }}>
      <Grid container spacing={4}>
        <Grid item xs={8} sm={6} sx={{ marginY: 'auto' }}>
          <div>
            If you&#39;re here, we&#39;re guessing something is broken, leaking,
            won&#39;t turn on, or won&#39;t turn off. We know how frustrating
            this can be!
          </div>
          <br />
          <div>
            Qeeper provides reliable On-demand property maintenance for your
            home.
          </div>
          <br />

          <div>
            From your desktop, tablet, or mobile phone, you&#39;re only one tap
            away from getting the service you need. Of course, if you like your
            property maintenance old school, you&#39;re also welcome to call us.
          </div>
        </Grid>
        <Grid item xs={8} sm={6}>
          <Image
            src="/images/family.jpeg"
            height="12"
            width="20"
            layout="responsive"
            alt="family image"
          />
        </Grid>
      </Grid>
    </Container>
  );
};
