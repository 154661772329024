import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

import { NEW } from '@common/constants';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import { DisplayErrors } from './InputUtil';

const icon = <MdCheckBoxOutlineBlank size="24" />;
const checkedIcon = <MdCheckBox size="24" />;
interface Props {
  name: string;
  options: any[];
  label?: string;
  placeholder?: string;
  freeSolo?: boolean;
  style?: any;
  multiple?: boolean;
  propertyName: string;
  helperPropertyName?: string;
}
const filter = createFilterOptions<any>();

export const AutoCompleteCheckInput = (props: Props) => {
  const {
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();
  const {
    name,
    options,
    label,
    placeholder,
    freeSolo = false,
    multiple = true,
    propertyName,
    helperPropertyName,
  } = props;
  const handleChange = (_: any, newValue: any[]) => {
    if (newValue && newValue.length > 0) {
      const values = newValue.map((option) => {
        if (option.inputValue) {
          return { id: NEW, name: option.inputValue };
        }
        return option;
      });
      setValue(name, values, { shouldValidate: true });
      return;
    }
    setValue(name, newValue, { shouldValidate: true });
  };
  const renderAutoComplete = (
    field: ControllerRenderProps<FieldValues, string>,
  ) => (
    <Autocomplete
      {...field}
      onChange={handleChange}
      multiple={multiple}
      id={name}
      options={options}
      disableCloseOnSelect
      includeInputInList
      openOnFocus={true}
      ChipProps={{ variant: 'outlined' }}
      isOptionEqualToValue={(option: any, value: any) =>
        option[propertyName] === value[propertyName]
      }
      getOptionLabel={(option: any) => {
        if (typeof option === 'string') {
          return option;
        } else if (typeof option === 'number') {
          return option;
        } else if (option.inputValue) {
          return option.inputValue;
        }
        return option[propertyName];
      }}
      filterOptions={(options: any[], params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) =>
            inputValue.toLowerCase() === option[propertyName].toLowerCase(),
        );
        if (freeSolo && inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            [propertyName]: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      renderOption={(props, option: any, { inputValue, selected }) => {
        const matches = match(option[propertyName], inputValue);
        const parts = parse(option[propertyName], matches);
        return (
          <li {...props}>
            <div>
              {multiple && (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              )}
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}>
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      style={props.style}
      renderInput={(params) => (
        <TextField
          {...params}
          id="radom-id-3"
          label={label}
          placeholder={placeholder}
          autoComplete="new-password"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          helperText={
            helperPropertyName && getValues(name)
              ? getValues(name)[helperPropertyName]
              : ''
          }
        />
      )}
      freeSolo={freeSolo}
    />
  );

  return (
    <>
      <Controller
        render={({ field }) => renderAutoComplete(field)}
        control={control}
        name={name}
      />
      <DisplayErrors message={errors[name]?.message} />
    </>
  );
};
